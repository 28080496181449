<template>
  <div style="height: 25px; display: flex; gap: 6px; align-items: flex-end; margin-bottom: 15px;">
    <div style="width: 12px; height: 12px; cursor: pointer;" @click="handleChangeSize('small')"
         :style="activeIconSize === 'small' ? 'background: #7f7f7f' : 'background: #d9d9d9'"/>
    <div style="width: 14px; height: 14px; cursor: pointer;" @click="handleChangeSize('medium')"
         :style="activeIconSize === 'medium' ? 'background: #7f7f7f' : 'background: #d9d9d9'"/>
    <div style="width: 16px; height: 16px; cursor: pointer;" @click="handleChangeSize('large')"
         :style="activeIconSize === 'large' ? 'background: #7f7f7f' : 'background: #d9d9d9'"/>
    <span style="font-size: 14px!important; font-family: auto; font-weight: bold;">T</span>
  </div>
</template>

<script>

export default {
  name: 'BlockSizeChange',
  props: {
    activeSize: {
      type: String,
      default: 'small',
      required: false
    }
  },
  emits: ['onChange'],
  data() {
    return {
      activeIconSize: this.activeSize || 'small',
      buttonStyleArray: [],
    }
  },
  mounted() {
    this.buttonStyleArray = [
      {
        name: this._t('label_Small'),
        value: 'display:inline-block; width:10rem; height:10rem;'
      },
      {
        name: this._t('label_Medium'),
        value: 'display:inline-block; width:12rem; height:12rem;'
      },
      {
        name: this._t('label_Large'),
        value: 'display:inline-block; width:16rem; height:16rem;'
      }
    ]
  },
  methods: {
    handleChangeSize(size) {
      this.activeIconSize = size;
      if (size === 'small') {
        this.$emit('onChange', this.buttonStyleArray[0].value)
      } else if (size === 'medium') {
        this.$emit('onChange', this.buttonStyleArray[1].value)
      } else {
        this.$emit('onChange', this.buttonStyleArray[2].value)
      }
    },
  }
}
</script>